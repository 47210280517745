<template>
  <list-template
    :search-config="searchConfig"
    @onSearch="onSearch"
    @onReset="onReset"
    :table-config="tableConfig"
    :table-data="tableData"
    :total="total"
    :loading="loading"
    :current-page="page"
    @onChangePage="handleCurrentChange"
    @onChange="onChange"
    @onExportData="handDownload"
  >
  </list-template>
</template>

<script>
import { tableListMixin } from '@/utils/mixins'
import { JSONToExcel } from "@/utils";
import http from "@/api";
export default {
  _config:{"route":{"path":"list","meta":{"title":"列表"}}},
  mixins: [tableListMixin],
  created() {
    this.getSearchConfig()
  },
  methods: {
    handDownload() {
      http.get('/api3/api/third_party_record/index', {...this.search, is_export: 1}).then(res => {
        const config = {}
        config.data = res.list
        config.name = '第三方调用记录'
        config.title = res.field_list
        JSONToExcel(config)
      })
    },
    getData() {
      this.getDataApi('/api3/api/third_party_record/index')
    },
    getSearchConfig() {
      http.get('/api3/api/third_party_record/config').then(res => {
        const { module_list, status_list, type_list } = res
        this.module_list = module_list
        this.searchConfig[0].options = type_list
        this.searchConfig[2].options = status_list
      })
    },
    onReset() {
      this.search = {}
      this.searchConfig[1].options = []
    },
    onBeforeSearch(res) {
      const { date, ...other } = res
      this.search = other
      if (date) {
        const [start_time, end_time] = date
        this.search.start_time = start_time
        this.search.end_time = end_time
      } else {
        if(this.search.start_time) this.search.start_time = ''
        if(this.search.end_time) this.search.end_time = ''
      }
    }
  },
  data() {
    return {
      module_list: [],
      searchConfig: [
        {
          tag: 'select',
          placeholder: '筛选类型',
          prop: 'type',
          options: [],
          change: (val, search) => {
            if(search.module) {
              search.module = ''
            }
            const obj = this.module_list.find(item => item.value === val)
            this.searchConfig[1].options = obj.child
          }
        },
        {
          tag: 'select',
          placeholder: '筛选模块',
          prop: 'module',
          options: [],
        },
        {
          tag: 'select',
          placeholder: '筛选状态',
          prop: 'status',
          options: []
        },
        {
          prop: 'date',
          placeholder: '筛选时间段',
          tag: 'datePicker',
          property: {
            clearable: false,
            type: 'daterange',
            size: 'small',
            style: 'margin-right:20px;',
            rangeSeparator: '-',
            startPlaceholder: '开始日期',
            endPlaceholder: '结束日期',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          }
        },
      ],
      tableConfig: [
        {
          prop: 'type_name',
          label: '类型',
        },
        {
          prop: 'module_name',
          label: '模块',
        },
        {
          prop: 'status_name',
          label: '调用状态',
        },
        {
          prop: 'created_at',
          label: '调用时间',
        },
        {
          prop: 'remark',
          label: '备注信息',
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped></style>
